@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  /* min-height: 300px; */
  /* height: 40vh; */
  padding-top: 3rem;
  max-height: none;
  padding: 0;

  @media (--viewportMedium) {
    /* min-height: 500px; */
    /* height: 70vh; */
    /* max-height: 600px; */
  }

  @media (--viewportLarge) {
    max-height: 800px;
    /* min-height: 600px; */
    /* height: calc(70vh - var(--topbarHeightDesktop)); */
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    /* padding-bottom: 83px; */
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-bottom: 60px;
  }
}

.sections {
  margin: 0;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  /* background-color: var(--matterColorLight); */
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  margin-top: 1vh;
}

.sectionContentCTA {
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}
.standContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 0;
  background: #fff;
}
.standImage {
  height: auto;
  width: 100%;
  @media (--viewportMedium) {
    width: 60%;
  }
}
.standHowItWorkMiddle {
  background: url('./images/HowItWorksBackground.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  margin: 0;
  width: 767px;
  padding: 3% 5% 5% 8%;
  @media (--viewportLargeWithPaddings) {
    height: 532px;
    margin: 0 30px;
  }
}

.standHowItWorkRight {
  display: none;
  @media (--viewportLargeWithPaddings) {
    display: block;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  font-family: var(--fancyFontFamily);
  color: #1a8995;
  margin-bottom: 30px;
}

.HowItWorkPoints {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 53px;
  }
}

.subTitle {
  @apply --marketplaceH3FontStyles;
  font-family: var(--fancyFontFamily);
  color: #3d3d3d;
  margin: 0;
}

.description {
  @apply --marketplaceH5FontStyles;
  font-family: var(--fontFamily);
  color: #3d3d3d;
  margin-bottom: 0;
}

.weHelpRestaurantsBlock {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (--viewportLarge) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
}

.weHelpRestaurantstitle {
  @apply --marketplaceH1FontStyles;
  font-family: var(--fancyFontFamily);
  color: #1a8995;
  margin-bottom: 30px;
  @media (--viewportLarge) {
    margin-bottom: 52px;
  }
}

.weHelpRestaurantssubTitle {
  @apply --marketplaceH3FontStyles;
  font-family: var(--fancyFontFamily);
  color: #3d3d3d;
  margin-bottom: 30px;
  @media (--viewportLarge) {
    margin-bottom: 52px;
  }
}

.weHelpRestaurants {
  padding: 0 30px;
  @media (--viewportLarge) {
    width: 45%;
  }
}
.weHelpRestaurantsLeft {
  width: 100%;
  @media (--viewportLarge) {
    width: 55%;
  }
}
.eatDrinkBeMerry {
  width: 100%;
}

.standHowItWorkLeft {
  padding: 0 30px;
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.linkButtonSection {
  display: flex;
  justify-content: center;
  @media (--viewportLarge) {
    justify-content: flex-start;
  }
}
.linkButton {
  @apply --marketplaceButtonStylesPrimary;
  display: flex;
}

.linkText {
  @apply --marketplaceH5FontStyles;
  min-width: 190px;
  margin: 10px 0;
}

.customerFeedback {
  background: var(--marketplaceColorDark);
  width: 100%;
}
