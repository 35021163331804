@import '../../marketplace.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}
.tableWrapper{
  max-width: 100%;
  overflow: scroll;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  margin: 2rem;
  overflow: scroll;
}
.td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.termsPageBackground {
background: none;
  @media (--viewportMedium) {
    background-attachment: fixed;
  }
}
