@import '../../marketplace.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 10px 24px;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 0 24px 10px 24px;
  }

  @media (--viewportLarge) {
    padding: 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 25px;
    padding-bottom: 20px;
  }

  @media (--viewportLarge) {
    /* padding-top: 73px; */

    /* border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative); */
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    padding: 0 20px;
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    /* padding: 0; */
  }
}

/* Mobile only social media links */
.someLiksMobile {
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    display: none;
  }
}
.iconWrapper {
  display: flex;
  justify-content: center;
  margin: 0 0.5rem;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  /* background-color: var(--matterColorLight); */
}
.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  text-align: center;
  margin-top: -2px;
}
.socialMediaIcon {
  color: var(--marketplaceColor);
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}
/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: none;
  margin-right: 24px;
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 27px;
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  /* margin-top: 24px; */
  margin-bottom: 0;
}

.copyrightLink {
  @apply --marketplaceFooterSecondaryFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLightAnti);
    text-decoration: none;
  }
}

.useDisclaimerText {
  @apply --marketplaceFooterSecondaryFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-bottom: 0;
}
/* Desktop and Mobile: links to more information */
.infoLinks {
  display: flex;
  flex-direction: row;
  /* margin-right: 24px; */
  /* margin-bottom: 24px; */
}

.list {
  display: flex;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
}

.listItem {
  min-height: 24px;
  margin: 1rem 0.6rem 0 0.6rem;
  color: var(--matterColorLight);
}

.link {
  /* Font */
  @apply --marketplaceFooterPrimaryFontStyles;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  display: none;
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;

  /* Layout */
  flex-direction: column;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}
.useDisclaimer {
  display: flex;
}
.tosAndPrivacy {
  display: flex;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: 100;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorAnti);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  /* background-color: var(--matterColorLight); */

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorAnti);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
.desktopSocialLinks {
  display: flex;
}
.bottomLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  flex-direction: column;
  text-align: center;
  @media (--viewportLargeWithPaddings) {
    flex-direction: row;
  }
}

.poweredBy {
  font-style: italic;
}
.specialLegal {
  margin: 0;
  line-height: 110%;
}
