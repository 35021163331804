@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--marketplaceColorDark);
}

.heroContent {
  display: flex;
  flex-direction: column;
  margin: 0 24px 0 24px;
  align-items: center;
  @media (--viewportMedium) {
    margin: 0 6vw 0 6vw;
    flex-direction: row;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1246px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}
.heroContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 100%;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-image: url(./images/BannerBackground.png);
  background-size: contain;
  background-position: center;
  min-height: 250px;

  @media (--viewportSmall) {
    width: 60%;
    background-position: center;
  }
  @media (--viewportMedium) {
    height: 220px;
    flex-basis: 40%;
    width: 40%;
  }
  @media (--viewportLarge) {
    height: 280px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  @media (--viewportLargeWithPaddings) {
    height: 340px;
  }
  @media (--viewportXLarge) {
    height: 400px;
  }
}

.logoTextWrapper {
  @media (--viewportMedium) {
    margin-top: -20px;
  }
}
.logoText {
  @apply --marketplaceH4FontStyles;
  font-family: var(--fancyFontFamily);
  font-size: 18px;
  font-weight: 400;
  color: #676b6f;
  margin: 0;
  text-align: center;
  @media (--viewportMedium) {
    @apply --marketplaceH3FontStyles;
    font-weight: 400;
    font-size: 24px;
  }
}
.heroContentRight {
  display: flex;
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
  @media (--viewportMedium) {
    padding: 2rem;
    flex-basis: 50%;
    width: 50%;
  }
}

.heroContentImage {
  width: 100%;
  height: 80%;
  @media (--viewportMedium) {
    height: 100%;
  }
}
.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorDark);

  @apply --SectionHero_animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.sectionSearchBlockTitle {
  font-family: var(--fancyFontFamily);
  @apply --marketplaceH1FontStyles;
  line-height: 32px;
  color: #2784c1;
  text-align: center;

  @media (--viewportMedium) {
    @apply --marketplaceHeroTitleFontStyles;
    font-weight: 500;
    line-height: 36px;
  }
  @media (--viewportLarge) {
    @apply --marketplaceHeroTitleFontStyles;
    font-weight: 500;
    line-height: 48px;
  }
}
.redFancyFont {
  color: var(--marketplaceColorRed);
  font-family: var(--fancyFontFamily);
}
