@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;

  @media (--viewportMedium) {
    margin: 60px 6vw 0 6vw;
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: var(--SearchPage_containerHeight);
  }

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding-left: 36px;
    padding-right: 36px;
    max-width: 1128px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    padding-left: 36px;
    padding-right: 36px;
    max-width: 1128px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;
  align-self: baseline;
  background-image: url('../../assets//ShapeStarsBuyReservationBG.png');
  background-repeat: no-repeat;
  background-position: center 20px;
  background-size: contain;

  @media (--viewportMedium) {
    padding: 18px;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    /* width: 50%; */
    /* flex-basis: 50%; */
  }

  @media (--viewportLarge) {
    width: 100%;
    flex-basis: 100%;
    padding: 20px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px 0 0;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 0 0;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 500px;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);
  width: 100%;
  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: var(--SearchPage_containerHeight);
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
.contentRight {
  display: none;
  @media (--viewportMedium) {
    display: inline-flex;
    flex-direction: column;
    width: 20%;
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
  }
  @media (--viewportLarge) {
    margin-top: 0px;
    padding-top: 60px;
    width: 80%;
  }
}

.heroContentImage {
  width: 80%;
  height: auto;
  @media (--viewportMedium) {
  }
}

.headerAddress {
  @apply --marketplaceH1FontStyles;
  font-family: var(--fancyFontFamily);
  color: #1a8995;
  margin-bottom: 30px;
  margin-top: 50px;
}
.redFancyFont {
  color: #b21719;
}
.headerAddressSuffix {
  /* margin-left: 0.5rem; */
}

.noDataBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: -25px;
  flex-direction: column;
  align-items: center;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.noDataTextBlock {
  background-image: url('../../assets/NoDataBg.png');
  background-repeat: no-repeat;
  width: auto;
  background-size: contain;
  min-height: 320px;
  background-position: center;
  text-align: center;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  @media (--viewportMedium) {
    padding-top: 100px;
    width: 320px;
    background-size: contain;
    background-position: center;
    text-align: center;
  }
  @media (--viewportLarge) {
    padding-top: 100px;
    padding-left: 45px;
    width: 360px;
    text-align: left;
  }
  @media (--viewportLargeWithPaddings) {
    padding-top: 185px;
    padding-left: 135px;
    background-size: 100% auto;
    background-position: top;
    width: 700px;
    height: 750px;
  }
  @media (--viewportXLarge) {
    padding-top: 185px;
    padding-left: 135px;
    background-size: 100% auto;
    background-position: top;
  }
}

.noDataHeading {
  @apply --marketplaceH3FontStyles;
  font-family: var(--fancyFontFamily);
  color: #3d3d3d;
  margin-top: 0;
  margin-bottom: 14px;
}

.noDataText {
  @apply --marketplaceH5FontStyles;
  font-family: var(--fontFamily);
  color: #3d3d3d;
  margin-bottom: 0;
}

.StarsWomanImgBlock {
  display: flex;
  @media (--viewportMedium) {
    justify-content: center;
  }
}

.StarsWomanImg {
  margin-top: 30px;
  margin-bottom: 30px;
}
