@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 24px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
    flex-direction: row;
  }
}

.error {
  color: var(--failColor);
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.cuisines {
  @apply --EditListingDescriptionForm_formMargins;
}

.submitButton {
  /* margin-top: auto; */
  margin-bottom: 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-bottom: 0;
    /* margin-top: 100px; */
  }
}

.locationAutocompleteInput {
  /* flex-grow: 1;
  height: 36px; */
  line-height: unset;
  padding-left: 0;
  margin: 0;
  /* border-bottom: 2px solid var(--matterColor); */
  padding: 10px 8px;

  &:hover,
  &:focus {
    /* border-bottom-color: var(--matterColor); */
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  /* border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius); */
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

/* .locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
} */
.dropdownSection {
  display: flex;
  flex-flow: row wrap;
  @apply --EditListingDescriptionForm_formMargins;
}
.dropdownItem {
  display: flex;
  flex-basis: 100%;

  flex-grow: 1;
  flex-direction: column;

  /* @media (--viewportMedium) {
    width: 30%;
    flex-basis: 30%;
  } */
}
.reservationDateSelection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  & label {
    font-weight: 600;
    margin: 0;
  }
  & > div {
    width: 100%;
    @media (--viewportMedium) {
      width: 300px;
    }
  }
  & input {
    background-color: #fff !important;
    min-height: 40px !important;
    background-position: 10px 10px !important;
    background-size: 15px !important;
    padding: 2px 5px 2px 35px !important;
  }
}

.paymentSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}
.priceSection {
  @apply --EditListingDescriptionForm_formMargins;
}
.calendarIcon {
  margin: 0.5rem;
  cursor: pointer;
}
.sectionTitle {
  @apply --marketplaceH3FontStyles;
  font-family: var(--fancyFontFamily);
  color: #3d3d3d;
  margin: 0 0 32px 0;
}
.paidText {
  text-decoration: line-through;
  margin-right: 0.5rem;
}
.labelClass {
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
}
.linkCursor {
  cursor: pointer;
}
.buttonDisplayWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 50px;
  }
}
.pleasePayMessage {
  display: flex;
  padding: 0 1rem;
}

.helperText {
  @apply --marketplaceTinyFontStyles;
  margin-top: -15px;
  color: var(--matterColorAnti);
}
.redFont {
  color: var(--marketplaceColorRed);
}

.RestaurantDetailsBlock,
.ReservationDetailsBlock {
  width: 100%;
  & :global(input) {
    width: 100%;
  }
  & :global(select) {
    width: 100%;
  }
  & :global(textarea) {
    width: 100%;
  }
  & :global(> div) {
    margin-bottom: 15px;
  }
  @media (--viewportMedium) {
    width: 50%;
    & :global(input) {
      width: 300px;
    }
    & :global(select) {
      width: 300px;
    }
    & :global(textarea) {
      width: 300px;
    }
    & :global(> div) {
      margin-bottom: 24px;
    }
  }
}

.ReservationNameLabel {
  &::before {
    content: 'Name reservation is under';
    font-size: 12px;
    float: right;
    margin: -3px 0 0 0;
    @media (--viewportMedium) {
      font-size: 14px;
      float: none;
      margin: 0;
    }
  }
  & label {
    float: left;
    margin: 0 5px 0 0;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: calc(50% - 12px);

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

/* .fieldSelect {
  flex-grow: 1;
  margin-left: 0; */
/* margin-right: 24px; */

/* &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fillRule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" strokeLinecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 0;
      bottom: 17px;
    }
  }
} */

/* .fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fillRule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" strokeLinecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  @media (--viewportMedium) {
    &::after {
      left: 0;
      bottom: 17px;
    }
  }
} */

.select {
  position: relative;
  padding-left: 24px;
  padding-right: 16px;
  /* background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fillRule="evenodd"/></svg>');
  background-size: 10px 6px; */
  border-bottom-width: 1px;
  border-bottom-color: #e1e4e8 !important;
  background-image: url(../../assets/time.png) !important;
  background-position: 10px 10px !important;
  padding: 2px 5px 2px 35px !important;
  min-height: 40px;

  @media (--viewportMedium) {
    /* border-bottom-width: 3px; */
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fillRule="evenodd"/></svg>');
}
