@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  overflow: hidden;

  @media (--viewportLarge) {
    padding: 109px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 109px 0 82px calc((100% - 1056px) / 2);
  }
}
.tabs {
  display: flex;
  flex-direction: row;
  /* overflow-x: auto; */
  min-height: 48px;
  padding-top: 2px;
  width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    /* height: 0px; */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 13px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
