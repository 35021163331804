@import '../../marketplace.css';

.root {
    fill: var(--marketplaceColor);

  transition: var(--transitionStyleButton);
  &:hover {
    fill: var(--marketplaceColor);
  }
}
