@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;
  --TopbarSearchForm_mobilePredictionTop: calc(
    var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
      var(--TopbarSearchForm_bottomBorder)
  );
}

.searchInputIcon {
  box-sizing: content-box;
  padding-left: 24px;
    margin-top: 1px;
height: 100%;
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
}

.searchInput {
  flex-grow: 1;
  height: 2rem;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;
  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }
}

.textInputRoot{
  height: 100%;
}