@import '../../marketplace.css';

.root {
  display: flex;
  width: 80%;
  height: 100%;
  text-align: center;
  justify-content: center;
}
.billboardRoot {
  height: 100%;
  width: 80%;
  @media (--viewportMedium) {
    /* width: 60%; */
  }
}
