@import '../../marketplace.css';

.topbar {
  z-index: 1;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.TipsSections {
  display: flex;
  background: var(--marketplaceColorLightBlueBackground);
  padding: 30px 0;
  @media (--viewportMedium) {
    padding: 60px 0;
  }
}
.TipsSectionsContainer {
  flex-grow: 1;
  max-width: 1024px;
  margin: 0px auto;
  height: auto;
  display: flex;
  padding: 0 15px;
  @media (--viewportMedium) {
    padding: 0 25px;
  }
}
.TipsSectionsLeft {
  width: 100%;
  @media (--viewportLargeWithPaddings) {
    width: 55%;
  }
}

.TipsSectionsRight {
  display: none;
  @media (--viewportLargeWithPaddings) {
    display: flex;
    align-items: center;
    width: 45%;
  }
}

.TipsTitle {
  @apply --marketplaceH1FontStyles;
  font-family: var(--fancyFontFamily);
  color: #1a8995;
  margin-bottom: 30px;
}

.TipsPoints {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 53px;
  }
}

.TipsSubTitle {
  @apply --marketplaceH3FontStyles;
  font-family: var(--fancyFontFamily);
  color: #3d3d3d;
  margin: 0;
}

.Tipsdescription {
  @apply --marketplaceH5FontStyles;
  font-family: var(--fontFamily);
  color: #3d3d3d;
  margin-bottom: 0;
}

.SellYourReservationTopBlock{
  background: url(../../assets/SellYourReservationBG.png) no-repeat;
  background-size: cover;
  background-position:top;
  padding-bottom: 25px;
  background-color: #fff;
  @media (--viewportLargeWithPaddings) {
    padding-bottom: 65px;
    background-size: contain;
  }
}