@import '../../marketplace.css';

.bottomHelperRoot {

  /* Dimensions */
  width: 100%;
  /* Layout */
  display: flex;
  /* flex-basis: 50%; */
  justify-content: center;
  /* overflow: hidden; */
  color: #ffffff;
  text-decoration: none;
}

.helperMan {
  width: 15%;
  min-width: 160px;
}
.helperBubble {
  position: relative;

  background: var(--marketplaceColorLight);
  display: inline-block;
  padding: 0.5rem;
  margin: 20px 20px 250px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  @media (--viewportMedium) {
    margin: 40px 40px 250px 40px;

    padding: 1rem;
  }
}

.helperBubble:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -32px;
  width: 70px;
  height: 30px;
  background-color: var(--marketplaceColorLight);
  transform: skew(55deg);
  transform-origin: top right;
  border-radius: 15% 0 0 0 / 25%;
  z-index: -1;
}
.helperContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.helperTitle {
  @apply --marketplaceHelperTitle;
  color: #000;
}
.helperSubTitle {
  @apply --marketplaceHelperSubTitle;
  color: #000;
}
.helperLink {
  @apply --marketplaceHelperLink;
}
.helperManLink {
  color: initial;
  fill: initial;
  width: 100%;
text-align: center;
}
