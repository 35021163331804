@import '../../marketplace.css';

.cardRoot {
  width: 100%;
}

.root {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  padding: 40px 10vw;

  @media (--viewportLarge) {
    /* padding: 85px 10vw; */
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
}

.cardWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.imageIndex {
  /* Fonts */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Position and dimensions */
  position: absolute;
  top: 21px;
  left: 20px;
  margin: 0;

  /* Colors */
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.prev,
.next {
  /* Position and dimensions */
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10vw;

  /* Clear button borders */
  border: none;
  border-radius: 0;

  outline: none;
  cursor: pointer;

  background-size: 13px auto;

  @media (--viewportMedium) {
    background-size: 20px auto;
  }

  @media (--viewportLarge) {
    opacity: 0.5;
    transition: var(--transitionStyleButton);

    &:hover {
      opacity: 1;
    }
  }
}

.prev {
  left: 0;
  background-image: url('../../assets/prev.png');
  background-position: center left 20px;

  @media (--viewportMedium) {
    background-position: center left 5vw;
    background-image: url('../../assets/prev.png');
  }
}

.next {
  right: 0;
  background-image: url('../../assets/next.png');
  background-position: center right 20px;

  @media (--viewportMedium) {
    background-position: center right 5vw;
    background-image: url('../../assets/next.png');
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0;
}

.loadingVisible {
  opacity: 1;
  transition: opacity var(--transitionStyle);
}

.image {
  /* Fit image in the available space as big as possible, keeping the aspect ratio */
  object-fit: contain;
}

.imageLoading {
  opacity: 0.1;
  /* transition: width 3s;
  transition-delay: 1s; */
}

.customerFeedbackContent {
  @apply --marketplaceH3FontStyles;
  font-family: var(--fancyFontFamily);
  color: #3d3d3d;
  margin-top: 30px;
  margin-bottom: 30px;
}

.customerDetail {
  @apply --marketplaceH5FontStyles;
  font-family: var(--fancyFontFamily);
  color: #1a8995;
}
