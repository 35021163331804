@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
  padding-top: 60px;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}
.contentWrapper{

  height: 300px;
  overflow-y: scroll;
  margin: .5rem;
  padding: .5rem;
  background-color: #f5f5f5;
  border: 1px solid #8d8d8d
}
.buttonWrapper{
  display: flex;
  
}
.confirmationButton{
  margin: 1rem;
}