@import '../../marketplace.css';

.root {
  border-radius: 2px;
}

.searchInputIcon {
  box-sizing: content-box;
  margin-top: 18px;
  margin-left: 18px;
  display: flex;
  width: 24px;
  align-self: stretch;
  /* border-bottom: 2px solid var(--marketplaceColor); */
  /* background-color: var(--matterColorLight); */
  position: absolute;
}

.searchInput {
  flex-grow: 1;
  height: auto;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  padding: 11px 18px 11px 48px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }
}

:global(.LocationSearchFormValMsg .ValidationMsg) {
  margin-top: 0;
  margin-bottom: -24px;
  position: absolute;
  bottom: 0;
  font-size: 14px;
}

.searchPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  background-color: var(--matterColorLight);
  /* border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius); */
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: var(--borderRadius);
  margin-top: 7px;
  margin-bottom: 102px;

  @media (--viewportXLarge) {
    margin-bottom: 0;
  }
}

.fieldDateInput {
  color: #c2c9d1;
  font-size: 17px;
  /* border-bottom-color: #000000 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important */
}

.field {
  width: 100%;
  position: relative;
  @media (--viewportLarge) {
    width: auto;
  }
}

.LocationAutocompleteInputLanding {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 0;
  min-height: 56px;
  width: 100%;
  @media (--viewportLarge) {
    margin: 0 30px;
    width: auto;
  }
}
