
/* If you need to add css to the Logo you can do it here. */
@import '../../marketplace.css';
.logoMobile {
  width: 80%;
  max-height: 60px;
}

.mobile {
  width: 80%;
  max-height: 60px;

}
