@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 100px;
  border: 1px solid #c2c9d1;
  /* background-color: rgba(255,255,255,.5); */
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  /* @media (--viewportLarge) {
    padding: 0 36px 96px 0px;
  } */
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on .the last column
 * .this is a special case in .the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect .the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to .the end of .the page. */
  margin-top: auto;
  /* background-color: var(--matterColorLight); */
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.table .tr {
  padding: 0.35em;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #fff;
}

.table .th,
.table .td {
  padding: 12px 16px;
  text-align: left;
}
.table .td {
  border-bottom: 1px solid #f0f2f3;
  padding: 8px 16px;
}

.doubleCell {
  text-align: left !important;
}
.doubleCell > a > div {
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
}
.buttonBlock {
  width: 220px;
}
.table .th {
  font-size: 14px;
  font-family: var(--fontFamily);
  font-weight: 400;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColorTableHead);
}

@media screen and (max-width: 1px) {
  .table {
    border: 0;
  }

  .table .caption {
    font-size: 1.3em;
  }

  .table .thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table .tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .table .td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .table .td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .table .td:last-child {
    border-bottom: 0;
  }
}

.hideSmall {
  display: none;
  @media (--viewportSmall) {
    display: revert;
  }
}
.td > a {
  font-size: 10px;
  color: var(--matterColorDarkText);

  @media (--viewportMedium) {
    font-size: 12px;
  }
  @media (--viewportLarge) {
    font-size: 14px;
  }
}
.dateHolderDate {
  /* white-space: nowrap; */
}
.SortIcon {
  padding: 10px;
  cursor: pointer;
}

.snatchReservationButtonLink:hover {
  text-decoration: none;
}
.snatchReservationButton {
  @apply --marketplaceButtonStylesTertiary;
}
