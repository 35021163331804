@import '../../marketplace.css';

.root {
}

/* .input {
  border-bottom-color: var(--matterColor);
}

.inputSuccess {
  border-bottom-color: var(--matterColor);
}

.inputError {
  border-bottom-color: var(--failColor);
} */

.textarea {
}
.iconWrapper {
  display: flex;
  flex-direction: row;
  height: 28px;
  background-color: #fff;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  box-shadow: var(--boxShadowTop);

  padding-top: 3px;
  @media (--viewportMedium) {
    height: 32px;
  }
}
