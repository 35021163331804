@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
  margin-top: 20px;
}
.buttonShadow {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  /* box-shadow: var(--boxShadow); */
}

.optionTab {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  flex-basis: 50%;
  flex-grow: 1;
  height: 50%;
  width: 100%;
  max-width: 35%;
  text-align: center;
  padding: 0.5rem;
  margin: .5rem;
  border-radius: 50%;
  background-color: var(--marketplaceColor);

  font-family: var(--fancyFontFamily);
  color: var(--matterColorAnti);
z-index: 10;
  @media (--viewportMedium) {
    font-size: 14px;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here */  
    content: none;
    width: 100%;
  height: 32px;
  text-align: revert;
  padding: revert;
  margin: revert;
  z-index: revert;

  border-radius: 0;
 }
}

.optionTab:before {
  content: '';
  position: absolute;
  width: 92.5%;
  height: 90%;
  font-size: 12px;
  text-align: center;
  padding: 0.5rem;
  z-index: -1;

  border-radius: 50%;
  background-color: var(--marketplaceColorRed);
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here */  
    content: none;
    width: 100%;
  height: 32px;
  text-align: revert;
  padding: revert;
  margin: revert;
  z-index: revert;

  border-radius: 0;
 }
  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.selectedOptionTab {
  /* border-bottom: 4px solid #a21f38;  */
  /* font-weight: 800; */
  font-size: 16px;
  color: var(--matterColorLight);

  box-shadow: var(--boxShadowPopupLight);

  @media (--viewportMedium) {
    font-size: 18px;
  }

}
.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkedStyle {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.checked {
  display: none;
}

.notChecked {
  stroke: var(--matterColorAnti);
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--attentionColor);
  &:hover {
    stroke: pink;
  }
}

.text {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
