@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.logoTextWrapper{
  @media (--viewportMedium) {
  margin-top: -20px;

  }
}
.logoText{
  @apply --marketplaceH4FontStyles;
  font-family: var(--fancyFontFamily);
  font-weight: 400;
  color: #2784C1;
  margin: 0;
  text-align: center;
  @media (--viewportMedium) {
  @apply --marketplaceH2FontStyles;
  font-weight: 400;

  }
}
.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
    justify-content: center;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 32px;
  margin-bottom: 16px;
}

.heroContentImage {
  width: 100%;
  height: 80%;
  @media (--viewportMedium) {
    height: 100%;
  }
}
.subtitle{
  text-decoration: underline;
}