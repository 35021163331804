@import '../../marketplace.css';

.root {
}

.root li {
  margin-bottom: 20px;

  box-shadow: var(--boxShadowPopupLight);
  @media (--viewportLarge) {
  }
}

.listingItemHolder {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (--viewportLarge) {
    /* align-items: center; */
  }
}
.secondaryInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 0.2rem;
}
.listingWrapper {
  display: block;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.listingDescription {
  display: flex;
  align-items: center;
  height: 25px;

  @media (--viewportLarge) {
    height: auto;
    text-align: center;
  }

  &:hover {
    & .icon {
      opacity: 1;
    }
  }
}

.description {
  font-size: 16px;
  line-height: 1.2;
  margin: 10px 0 0;

  & span {
  }
}

.contentHolder {
  display: block;
  margin-top: 10px;

  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.listingTitle {
  display: flex;
  align-items: center;

  flex-basis: 30%;
  font-weight: bold;
  margin: 0;
  margin-left: 2rem;
  color: var(--matterColor);
  line-height: 1rem;
  max-height: 1rem;
  text-transform: capitalize;
  width: 100%;
  padding: 0.2rem;
  /* word-break: break-all; */

  @media (--viewportLarge) {
    line-height: 1.2rem;
  }
}

.listingTitleHeader {
  display: flex;
  align-items: center;
  flex-basis: 30%;
  font-weight: bold;
  margin: 0;
  color: var(--matterColor);
  text-transform: capitalize;
  width: 100%;
  margin-left: 2rem;

  /* word-break: break-all; */
  font-family: var(--fancyFontFamily);
  color: var(--marketplaceColorRed);
  font-size: 1rem;
  padding-top: 0.5rem;

  @media (--viewportLarge) {
    margin-left: 0rem;
  }
}
.listingPrice {
  display: flex;
  flex-basis: 10%;
  justify-content: flex-end;
  margin-right: 1rem;
  font-weight: 800;
  margin-right: 10px;
  width: 100%;
  color: var(--marketplaceColor);
  white-space: nowrap;
}
.headerListingPrice {
  display: flex;
  flex-basis: 10%;
  justify-content: center;
  margin-right: 2rem;
  font-weight: 800;
  width: 100%;
  font-family: var(--fancyFontFamily);
  color: var(--marketplaceColorRed);
  font-size: 1rem;
  padding-top: 0.5rem;

  @media (--viewportLarge) {
    margin-right: 1rem;
  }
}

.dateTimeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportLarge) {
    min-width: 150px;
    flex-direction: row;
  }
}
.actionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  justify-content: space-between;
}
.listingPriceContainer {
  display: flex;
  align-items: center;
  color: #6f6f6f;
  margin-right: 12px;
  text-align: right;
  line-height: 1;

  @media (--viewportLarge) {
    display: inline-block;
  }
}

.selectlistingButton {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: var(--fancyFontFamily);
  max-width: 122px;
  min-height: 30px;
  padding: 0 4px;
  white-space: nowrap;
  background: none;
  color: var(--matterColorLight);
  border: none;
  border-radius: 20px;
  background: var(--marketplaceColorRed);
  font-size: 0.7rem;
  &:hover,
  &:focus {
    /* font-weight: var(--fontWeightBold); */
    color: var(--matterColorLight);
    background: var(--marketplaceColorRed);
    text-decoration: none;
    box-shadow: var(--boxShadowPopup);
  }
  @media (--viewportLarge) {
    font-size: inherit;

    padding: 2px 10px 0;
  }
}
.startTime {
  width: 100%;
  min-width: 75px;
  display: block;
  color: var(--matterColor);
  font-weight: 600;
  line-height: 1;
}
.listingHoursEst {
  display: none;
  width: 100%;
  min-width: 75px;
  color: var(--matterColorAnti);
  line-height: 1;
  text-align: left;
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 10%;
    justify-content: flex-end;
    margin-right: 1.5rem;
  }
}

.headerCuisineOption {
  display: none;
  width: 100%;
  min-width: 75px;
  color: var(--matterColor);
  text-transform: capitalize;
  font-family: var(--fancyFontFamily);
  color: var(--marketplaceColorRed);
  font-size: 1rem;
  padding-top: 0.5rem;
  line-height: 1;
  text-align: center;
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 10%;
    justify-content: flex-end;
    margin-right: 1rem;
  }
}
.cuisineOption {
  display: none;
  width: 100%;
  min-width: 75px;
  color: var(--matterColor);
  text-transform: capitalize;

  line-height: 1;
  text-align: center;
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 10%;
    justify-content: flex-end;
    margin-right: 1rem;
  }
}

.descriptionText {
  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
}

.icon {
  height: 18px;
  width: 18px;
  opacity: 1;
  margin-right: 6px;

  & circle {
    fill: #f6bd60;
  }

  & polygon {
    fill: var(--matterColorLight);
  }
}

.tooltip {
  text-transform: capitalize;
}

.wrapperClass {
  display: flex;

  & svg {
    margin-left: 0;
  }
}

.experience {
  margin: 0;
}

.dateHeader {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  width: 80%;

  /* background-color: var(--midButtonHover); */
  /* border-radius: 2rem; */
}
.dateText {
  border-radius: 2rem;
  display: inline-flex;
  padding: 0.5rem 1rem;
  background: #f28482;
  color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background: var(--buttonHover);
  }
}
.showMoreButton {
  border-radius: 2rem;
  display: inline-flex;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  background: #f28482;
  color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out;
  justify-content: center;
  max-width: 150px;
  &:hover {
    background: var(--buttonHover);
  }
}
.upcomingEventsWrapper {
  display: flex;
  flex-direction: column;
}
.headerWrapper {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--matterColorAnti);
}
.resultsWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: 4px dotted var(--matterColorAnti);
}
.buttonSliderHolder {
  position: relative;
  margin-left: auto;
}
.sliderHolder {
  position: relative;
  transition: 0.5s ease;
  visibility: visible;
}

.imageHolderSquare {
  /* display: none; */
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 90px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 0.5rem;
    /* background-color: var(--marketplaceColorDark); */
    top: 0;
    left: 90px;
    overflow: hidden;
    width: 90px;
    height: 40px;
    transition: 0.5s ease;
  }
}
.imageHolderSquareHeader {
  display: none;
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 110px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 0.5rem;
    /* background-color: var(--marketplaceColorDark); */
    top: 0;
    left: 110px;
    overflow: hidden;
    width: 110px;
    height: 40px;
    transition: 0.5s ease;
  }
}

.imageHolderImage {
  display: flex;
  overflow: hidden;
  object-fit: cover;
  width: 110px;
  height: 40px;
}

.resultsWrapper:hover .listingPrice {
  @media (--viewportLarge) {
    width: 0px;
    left: -10px;
    visibility: hidden;
    right: 160px;
  }
}
.resultsWrapper:hover .listingHoursEst {
  @media (--viewportLarge) {
    width: 0px;
    left: -10px;
    visibility: hidden;
    right: 160px;
  }
}
.resultsWrapper:hover .cuisineOption {
  @media (--viewportLarge) {
    width: 0px;
    left: -10px;
    visibility: hidden;
    right: 160px;
  }
}

.resultsWrapper:hover .sliderHolder {
  /* @media (--viewportLarge) {
    margin-left: -2px;
    opacity: 0;
  } */
}

.buttonContainer {
  width: 65px;
  margin-right: 0.3rem;
  @media (--viewportLarge) {
    width: 150px;
  }
  @media (--viewportLarge) {
    display: flex;
    flex-basis: 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: 0.2rem;
    top: 0;
    right: 0px;
    overflow: hidden;
    width: 0px;
    height: 40px;
    transition: 0.5s ease;
  }
}

.resultsWrapper:hover .buttonContainer {
  width: 65px;
  @media (--viewportLarge) {
    width: 150px;
  }
  /* left: 160px; */
}

.dateHolderSquare {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin-right: 0.2rem; */
  /* height: 40px; */
  width: 50px;
  text-align: center;
  color: var(--marketplaceColor);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  @media (--viewportLarge) {
    height: 40px;

    margin-right: 0.2rem;
    width: 60px !important;
    min-width: 60px;
    text-align: left;
  }
}
.dateHolderDate {
  /* display: flex; */
  @apply --marketplaceSmallFontStyles;
}

.dateHolderMonth {
  /* display: flex; */
  @apply --marketplaceSmallFontStyles;
  font-weight: bold;
}
.headerText {
  display: flex;
  text-align: center;
  font-family: var(--fancyFontFamily);
  color: var(--marketplaceColorRed);
  font-size: 1rem;
  padding-top: 0.5rem;
  margin-right: 0.5rem;
}

.headerTextSeats {
  visibility: hidden;
  display: flex;
  text-align: center;
  font-family: var(--fancyFontFamily);
  color: var(--marketplaceColorRed);
  font-size: 1rem;
  padding-top: 0.5rem;
  margin-right: 0.5rem;
  @media (--viewportLarge) {
    visibility: visible;
  }
}

.headerTextSnatch {
  @media (--viewportLarge) {
    display: none;
  }
}
.listingDescriptionWrapper {
  display: flex;
  align-items: center;

  width: 100%;
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.zoomButton {
  background: none;
}
.zoomButton:hover {
  background: none;
  box-shadow: none;
}

.imageHolderImage {
  display: flex;
  position: absolute;
  overflow: hidden;
  object-fit: cover;
  width: 150px;
  height: 40px;
  top: 0;
  left: 0;
  /* z-index: 0; */
}
.timeAndImageHolder {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.timeText {
  display: flex;
  @apply --marketplaceSmallFontStyles;

  justify-content: center;
  z-index: 0;
  color: var(--marketplaceColor);
  white-space: nowrap;
  @media (--viewportMedium) {
  }
  /* text-shadow: 2px 2px #000; */
}
.draftWrapper {
  background-color: var(--failColorLight);
}
.timeAt {
  display: none;
  @media (--viewportLarge) {
    display: inline;
    @apply --marketplaceSmallFontStyles;
  }
}
.resultsList {
  list-style: none;
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}
.closedListing {
  opacity: 0.5;
}
