@import '../../marketplace.css';

.root {
}

.select {
  color: var(--matterColorAnti);
  /* border-bottom-color: var(--matterColor); */
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  /* border-bottom-color: var(--matterColor); */
}

.selectError {
  /* border-bottom-color: var(--failColor); */
}
