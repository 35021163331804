@import '../../marketplace.css';

.root {
  display: none;
  @media (--viewportLarge) {
    display: flex;
    width: 80%;
    height: 100%;
    text-align: center;
    justify-content: center;
  }
}
.billboardRoot {
  /* height: 100%; */
  /* width: 100%; */
}
