@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionSearchBlock_desktopTitleMaxWidth: 625px;

  --SectionSearchBlock_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  /* @apply --backgroundImage; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    margin-top: 2vh;
  }
  /* Text positioning is done with paddings */
}
.sectionSearchBlockTitle {
  padding-top: 2rem;

  @apply --marketplaceH3FontStyles;

  @media (--viewportLarge) {
    @apply --marketplaceHeroTitleFontStyles;
    font-weight: 500;
  }
}
.searchBackground {
  background-color: #fff;
  padding: 15px;
  border: 3px solid #38c4e4;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
    align-items: flex-end;
    padding: 25px 30px 30px 30px;
  }
}

.linkHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.redFancyFont {
  color: var(--marketplaceColorRed);
  font-family: var(--fancyFontFamily);
}
.goldFancyFont {
  color: var(--marketplaceColorSign);
  font-family: var(--fancyFontFamily);
}
