@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --DateRangeInput_selectionHeight: 36px;
  --DateRangeInput_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  /* Distance to top */
  --DateRangeInput_top: 252px;
}

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.DateRangePicker) {
    display: block;
  }
  & :global(.DateRangePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - var(--DateRangeInput_top));
    background-color: var(--marketplaceColor);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 42px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    @apply --marketplaceH4FontStyles;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
  }
  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorLight);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
    padding: 5px 0 2px 0;

    @media (--viewportMedium) {
      padding: 6px 0 9px 0;
    }
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorLight);
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 400;
  }

  & :global(.DayPicker__withBorder) {
    border-radius: 0;
  }

  & :global(.CalendarMonth_caption) {
    color: var(--matterColorLight);
    @apply --marketplaceH2FontStyles;
    margin: 1px 0 14px;
    font-weight: 400;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fillRule='evenodd'/></svg>");
    background-position: center 34px;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--successColor);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_start) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--successColor);
    border-top-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered_start) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_end) {
    background-color: transparent;
  }
  & :global(.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--successColor);
    border-top-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    color: var(--matterColorLight);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput_input__disabled) {
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.DateRangePickerInput) {
    width: calc(100vw - 48px);
    margin: 0 24px 3px 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: calc(50% - 6px);
    background: none;

    @media (--viewportMedium) {
      width: calc(50% - 12px);
    }
  }

  & :global(.DateInput:first-of-type) {
    margin-right: 12px;

    @media (--viewportMedium) {
      margin-right: 24px;
    }
  }
}

.arrowIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}
