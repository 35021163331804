@import '../../marketplace.css';

.root {
  stroke: var(--marketplaceColor);
  border-bottom: 2px solid var(--marketplaceColor);
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}