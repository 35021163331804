@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.termsPageBackground {
  background: none;
  @media (--viewportMedium) {
    background: url('../../assets/background_blob.svg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top center;
  }
}
