@import '../../marketplace.css';

.root {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* background: url('./images/oval.svg'); */

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 50%;
  height: auto;
}

.root:before {

  content: '';
  position: absolute;
clip-path: polygon(18% 9%, 75% 0, 69% 80%, 24% 86%);

 width:100%;
 height:100%;
  background-color: var(--marketplaceColorLight);
  /* transform: skew(75deg); */
  /* transform-origin: top right; */
  z-index: -1;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here */  
    display: none;
 }
}


.OvalLinkTypeOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--marketplaceColor); */
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  padding: 0.2rem;
  margin: 5%;

}
.OvalLinkTypeInner {
  display: flex;
  background-color: var(--marketplaceColor);
  padding: 1rem 4rem;
  color: var(--matterColorLight);
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 0 4px var(--matterColorLight);
  font-family: var(--fancyFontFamily);
}
.OvalLinkTypeInner > a {
  color: var(--matterColorLight);
white-space: nowrap;
}
.ovalAltColor{
  background-color: var(--marketplaceColorSign);

}