.root {
  display: flex;
  flex-direction: column;
}
.background {
  min-height: 100vh;
  background: url('../../assets/background_blob.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
