@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.helperIcon {
  z-index: 1;
  position: fixed;
  bottom: 48px;
  right: 1rem;
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  fill: var(--matterColorAnti);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}
.hasSeenTourWrapper {
  position: absolute;
  top: 0px;
  left: 5px;
  display: flex;
  align-items: center;
  width: 50%;
  /* background: var(--matterColorNegative); */
  opacity: 0.2;
  border-radius: 24px;
  padding: 0.2rem;
  margin: 0;
  line-height: 1em;

  @media (--viewportMedium) {
    width: 100%;
  }
}
.hasSeenTourText {
  @apply --marketplaceTinyFontStyles;
  margin: 0;
}
.hasSeenTourCheckbox {
  padding: 0.2rem;
}

.actionBar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% );
  color: var(--matterColorNegative);
  margin: 0 auto;
  /* max-width: 1024px; */
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH6FontStyles;
  display: none;
  text-transform: none;
  
  @media (--viewportMedium) {
    display: inline;
  max-width: 600px;

    margin: 12px;
  }
}

.mobileOwnListingText {
  @apply --marketplaceH6FontStyles;
  display: inline;
  text-transform: none;
  margin: 6px;
  max-width: 600px;
  @media (--viewportMedium) {
    display: none;
  }
}
.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH6FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 8px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
  display: inline;
}
